import { ICustomForm } from "../../../models/CustomForm";
import { fillSelection } from "../../../Utils/fuctions";
import { landKind } from "../selections";
import { LAND_KIND } from "../../../models/RealEstateLand";

export interface IDescriptionFormLand {
  landKind: LAND_KIND;
  buildabilityIndex: number;
  totalSquareMeters?: number;
  buildableSquareMeters?: number;
  agriculturalSquareMeters?: number;
  constructionPresent?: boolean;
  possibleProduction?: string;
  approvedProject?: boolean;
  description: string;
  additionalDetails: {
    easementOfPassage: string;
    preEmptionRights: string;
    plantations: string;
    exposition: string;
  };
}

export const initialValueDescriptionFormLand = {
  landKind: LAND_KIND.NOT_PROVIDED,
  buildabilityIndex: 0,
  totalSquareMeters: 0,
  buildableSquareMeters: 0,
  agriculturalSquareMeters: 0,
  constructionPresent: false,
  possibleProduction: "",
  approvedProject: false,
  description: "",
  additionalDetails: {
    easementOfPassage: "",
    preEmptionRights: "",
    plantations: "",
    exposition: "",
  },
};
const prefixLabelDescription = "realEstate.forms.description";

export const descriptionFormLand: ICustomForm<IDescriptionFormLand> = {
  title: {
    edit: `${prefixLabelDescription}.title`,
    show: `${prefixLabelDescription}.title`,
  },
  description: {
    edit: `${prefixLabelDescription}.description.edit`,
    show: `${prefixLabelDescription}.description.show`,
  },
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },

  next: "other-info",
  forms: [
    {
      title: `${prefixLabelDescription}.features.title`,
      fields: [
        {
          label: `${prefixLabelDescription}.features.labels.landKind`,
          type: "select",
          column: 3,
          key: "landKind",
          options: fillSelection(landKind),
        },
        {
          label: `${prefixLabelDescription}.features.labels.buildabilityIndex`,
          column: 2,
          type: "number",
          typeField: "decimal",
          key: "buildabilityIndex",
        },
        {
          label: `${prefixLabelDescription}.features.labels.totalSquareMeters`,
          column: 2,
          isRequired: true,
          type: "number",
          typeField: "currency",
          key: "totalSquareMeters",
        },
        {
          label: `${prefixLabelDescription}.features.labels.buildableSquareMeters`,
          column: 2,
          isRequired: true,
          type: "number",
          typeField: "currency",
          key: "buildableSquareMeters",
        },
        {
          label: `${prefixLabelDescription}.features.labels.agriculturalSquareMeters`,
          column: 2,
          type: "number",
          typeField: "currency",
          key: "agriculturalSquareMeters",
        },
        {
          label: `${prefixLabelDescription}.features.labels.possibleProduction`,
          type: "text",
          column: 3,
          key: "possibleProduction",
        },
        {
          label: "",
          type: "checkbox",
          column: 3,
          key: "constructionPresent",
          option: {
            label: `${prefixLabelDescription}.features.labels.constructionPresent`,
            value: false,
          },
        },
        {
          label: "",
          type: "checkbox",
          column: 3,
          key: "approvedProject",
          option: {
            label: `${prefixLabelDescription}.features.labels.approvedProject`,
            value: false,
          },
        },
        {
          label: `${prefixLabelDescription}.features.labels.description`,
          column: 12,
          isRequired: true,
          type: "text-area",
          key: "description",
        },
      ],
    },
    {
      title: `${prefixLabelDescription}.additionalFeatures.title`,
      fields: [
        {
          label: `${prefixLabelDescription}.features.labels.exposition`,
          type: "text",
          column: 3,
          maxColumn: 9,
          key: "additionalDetails/exposition",
        },
        {
          label: `${prefixLabelDescription}.features.labels.easementOfPassage`,
          column: 4,
          type: "text-area",
          key: "additionalDetails/easementOfPassage",
        },
        {
          label: `${prefixLabelDescription}.features.labels.preEmptionRights`,
          column: 4,
          type: "text-area",
          key: "additionalDetails/preEmptionRights",
        },
        {
          label: `${prefixLabelDescription}.features.labels.plantations`,
          column: 4,
          type: "text-area",
          key: "additionalDetails/plantations",
        },
      ],
    },
  ],
};
